export const Layout = {

    menuWidth: 250,
    filterWidth: 240,
    marginTop: 10,
    logoWidth: 180,
    itemContainerHeight: 300,
    itemImageHeight: 160,
    itemContainerWidth: 420,
    undoBarHeight: 60,
    undoBarImageHeight: 55
    
}