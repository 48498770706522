import { TouchableOpacity, View, Text } from 'react-native';
import myStyles from '../styles/myStyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';


const ShowIconCatalog = ({ icon, size, state, colorActive, colorInactive, text, press, show }) => {
    if (show) {
        return (
            <View
                style={myStyles.common.icon}
            >
                <TouchableOpacity
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        height: 50,
                        paddingRight: 18,
                        alignItems: 'center',
                        paddingLeft: 10
                    }}
                    onPress={() => press()}
                >
                    <FontAwesomeIcon icon={icon} size={size} color={state ?.room ?.expanded ? colorActive : colorInactive} />
                    <Text style={[myStyles.common.title]}>{text}</Text>
                </TouchableOpacity>
            </View>
        )
    } else {
        return (
            <View></View>
        )
    }
}



const ShowIconRoom = ({icon, size, color, text, press, show}) => {
    if (show) {
        return (
            <View
                style={myStyles.common.icon}
            >
                <TouchableOpacity 
                    style={[myStyles.common.menu, myStyles.room.btnActive ]} 
                    onPress={() => press()}
                >
                    <FontAwesomeIcon icon={icon} size={size} color={color} />
                    <Text style={myStyles.common.menuText}>{text}</Text>
                </TouchableOpacity>
            </View>
        )
    } else {
        return (
            <View></View>
        )
    }
}

export default {Room: ShowIconRoom, Catalog: ShowIconCatalog};
