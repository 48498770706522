import React, { Component } from 'react';
import { Text, Easing, Animated, Clipboard, TouchableOpacity, View, FlatList, RefreshControl } from 'react-native';
import { Colors } from '../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import utils from '../utils/utils';

import SpeechBubble from '../assets/svg/speechBubble';


// import { useHover, useFocus, useActive } from 'react-native-web-hooks';

import { faHeart as farHeart, faCopy as farCopy } from '@fortawesome/free-regular-svg-icons';

import myStyles from '../styles/myStyles';
import { Layout } from '../constants/Layout';

import VS from '../components/VS';

const imageRootURL = VS.RootURL;


export default class RenderItem extends Component {

	itemHeight = new Animated.Value(Layout.itemContainerHeight);
	toastOpacity = new Animated.Value(0);

	handleToggle = (item, hearts, config) => {
		if (this.props.screen == 'favorites') {
			Animated.timing(this.itemHeight, {
				toValue: 0,
				duration: 400,
				easing: Easing.out(Easing.ease),
				useNativeDriver: false,
			}).start(() => {
				this.toggleHeart(item, hearts, config);
			});
		} else {
			this.toggleHeart(item, hearts, config);
		}
	}

	toggleHeart = (item, hearts, config) => {
		item.heart = !item.heart;
		// this.props.heartToggleParent(item);
		utils.heartToggle(item, hearts, null, null, config);
		this.forceUpdate();
	}

	copyItemName = (item) => {
		Clipboard.setString(item.name);
		Animated.timing(this.toastOpacity, {
			toValue: 1,
			duration: 200,
			easing: Easing.out(Easing.ease),
			useNativeDriver: false
		}).start(() => {
			setTimeout(() => {
				Animated.timing(this.toastOpacity, {
					toValue: 0,
					duration: 200,
					easing: Easing.out(Easing.ease),
					useNativeDriver: false,
				}).start();
			}, 500);
		});
	}

	render() {
		const { item, index, category, roomName, itemCount, width, Styles, heartsParent, config } = this.props;

		let itemCategory = item.types[0];
		let itemRoom = item.rooms[0];
		const itemImage = imageRootURL + item.loc + item.name + '/' + item.name + 'front.png';

		// When rendering catalog
		if (roomName && category == null && item.rooms.length > 1) {
			const i = item.rooms.indexOf(roomName);
			itemCategory = item.types[i];
			itemRoom = item.rooms[i];
		}

		const itemLabel = this.props.screen == 'favorites' ? itemRoom + ' | ' + itemCategory : itemCategory
		const itemName = item.name.substring(1).toUpperCase();

		return (
			<Animated.View style={{
				height: this.itemHeight-10, 
				// paddingBottom: this.itemHeight / 3,
				paddingTop: 10,
				borderWidth: 0.2,
				borderColor: Colors.LIGHTGRAY,
				borderRadius: 12,
			}}>

				{/* Top row */}
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 15,
						height: 27
					}}
				>
					{/* <View style={{flex: 1}}> */}
					<Text style={[myStyles.common.itemName, myStyles.common.itemCategory]} >{category ? "" : itemLabel}</Text>
					{/* </View>
					<View style={{flex: 1}}> */}
					<TouchableOpacity
						onPress={() => this.handleToggle(item, heartsParent, config)}
						style={{ paddingRight: 10 }}
					>
						{
							item.heart != true &&
							<FontAwesomeIcon icon={farHeart} size={24} color={Colors.MEDGRAY} />
						}
						{
							item.heart == true && config ?.source != 'remote' &&
							<FontAwesomeIcon icon={'heart'} size={27} color={config ?.source == 'remote' ? Colors.ORANGE : Colors.RED} />
						}
					</TouchableOpacity>
					{/* </View> */}
				</View>

				{/* <View style={myStyles.common.itemInfoTop}>
					<Text style={[myStyles.common.itemName, myStyles.common.itemCategory]} >{category ? "" : itemCategory}</Text>
					<FontAwesomeIcon icon='heart' size='20' color='red' />
	
				</View> */}
				{/* Item image */}
				<Animated.Image
					source={{ uri: itemImage }}
					style={{
						paddingLeft: 10,
						paddingRight: 10,
						height: Layout.itemImageHeight,
						width: width,
						transform: [
							{
								translateY: this.itemHeight.interpolate({
									inputRange: [0, Layout.itemContainerHeight],
									outputRange: [-Layout.itemContainerHeight / 3, 0]
								})
							},
							{
								scaleX: this.itemHeight.interpolate({
									inputRange: [0, Layout.itemContainerHeight],
									outputRange: [0.2, 1]
								})
							},
							{
								scaleY: this.itemHeight.interpolate({
									inputRange: [0, Layout.itemContainerHeight],
									outputRange: [0.2, 1]
								})
							},
							// {
							// 	scaleY: this.itemHeight/Layout.itemContainerHeight
							// }
						]
						// marginRight: 10
					}}
					resizeMode="contain"
				/>
				{/* Styles row */}
				<Styles styles={item.styles} width={width}/>

				{/* Bottom row */}
				<View style={[myStyles.common.itemInfoBottom]}>
					<View
						style={{
							flex: 1,
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						{/* <View style={{flex: 2}}> */}
						<Text style={myStyles.common.itemIndex} >{index + 1}/{itemCount}</Text>
						{/* </View> */}
						<View>
							<TouchableOpacity
								onPress={() => this.copyItemName(item)}
								style={{
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'flex-start'
								}}
							>
								<Animated.View style={{ top: -21, left: 112, opacity: this.toastOpacity }}>
									<Text style={{ fontSize: 12, color: Colors.MEDGRAY }}>Copied to clipboard...</Text>
								</Animated.View>
								<FontAwesomeIcon icon={farCopy} size={18} color={Colors.LIGHTGRAY} />
								<Text style={[myStyles.common.itemName, { letterSpacing: 0 }]} >{itemName}</Text>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				{/* <View style={myStyles.common.childHr} /> */}
			</Animated.View>
		)
	}
}
