import { StyleSheet } from 'react-native';

import { Colors } from '../constants/Colors';

const logoStyles = StyleSheet.create({
    width: 180
});

const filterStyles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 50,
        // paddingLeft: 25,
        // paddingRight: 18,
        alignItems: 'center',
        backgroundColor: 'Colors.BODY_HEADER',
    },
    itemRow: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: Colors.FILTER,
        width: '100%',
        height: 36,
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 5,
        fontSize: 15,
    },
    icon: {
        paddingLeft: 2,
        paddingRight: 12,
        fontSize: 15,
    },

    heading: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        // paddingLeft: 25,
        // paddingRight: 18,
        alignItems: 'center',
        // paddingTop: 10,
        flexDirection: 'row',
        height: 50,
        // paddingLeft: 10,
        // paddingBottom: 10,
        // paddingRight: 10,
        fontSize: 18,
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight"
    },
    subHeadingLarge: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.DARKGRAY,
        fontFamily: "LatoBold"
    },
    subHeadingSmall: {
        paddingTop: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight"
    },
    childHr: {
        height: 1,
        backgroundColor: Colors.LIGHTGRAY,
        width: '100%',
    },
})

const bodyStyles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 50,
        // paddingLeft: 25,
        // paddingRight: 18,
        alignItems: 'center',
        backgroundColor: 'Colors.BODY_HEADER',
    },
});

const roomStyles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: Colors.LIGHTGRAY,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 50,
        paddingLeft: 25,
        paddingRight: 18,
        alignItems: 'center',
        backgroundColor: Colors.ROOM,
    },
    childRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Colors.ROOM,
    },
    btnActive: {
        backgroundColor: Colors.ROOM_ACTIVE
    },
});

const heartStyles = StyleSheet.create( {
    hr: {
        height: 1,
        backgroundColor: Colors.LIGHTGRAY,
        width: '100%',
    },
	undoContainer: {
		height: 90,
		// backgroundColor: "#f5f5f5",
		// justifyContent: "center",
		// alignItems: "center",
		// borderRadius: 10,

		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,

		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-evenly",
    },
	undoRow: {
        // width: "90%",
        
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-evenly",
    },
    
	undoText: {
        fontFamily: "LatoRegular",
        fontWeight: "regular", 
        fontSize: 17,
        paddingLeft: 2,
        color: Colors.DARKGRAY
	},

    undoTextBold: {
        fontFamily: "LatoLight",
        fontWeight: "regular", 
        fontSize: 16,
        paddingLeft: 2,
        color: Colors.DARKGRAY
	},

});


const commonStyles = StyleSheet.create({
    inputRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    input: {
        height: 40,
        width: 300,
        margin: 4,
        borderWidth: 1,
        borderColor: Colors.MEDGRAY,
        padding: 10,
        borderRadius: 4,
        color: Colors.DARKGRAY,
        placeholderTextColor: Colors.MEDGRAY
    },
    button: {
        width: '100%',
        height: 48,
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 18,
        fontSize: 18,
    },
    title: {
        fontSize: 18,
        fontWeight: 'normal',
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight"
    },
    itemActive: {
        fontSize: 18,
        color: Colors.GREEN,
        fontFamily: "LatoLight"
    },
    itemInactive: {
        fontSize: 18,
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight"
    },
    btnActive: {
        borderColor: Colors.GREEN,
        backgroundColor: Colors.ACTIVE
    },
    btnInactive: {
        borderColor: Colors.DARKGRAY,
    },
    icon: {
        height: 50,
    },
    menu: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: 50,
        paddingLeft: 12,
        paddingRight: 18,
        alignItems: 'center',
        // backgroundColor: Colors.CGRAY,
    },
    menuText: {
        fontSize: 21,
        fontWeight: 'normal',
        color: Colors.DARKGRAY,
        fontFamily: "LatoBold",
        paddingLeft: 10,
        paddingRight: 10
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 50,
        paddingLeft: 25,
        paddingRight: 18,
        alignItems: 'center',
        backgroundColor: Colors.CGRAY,
    },
    parentHr: {
        height: 0,
        color: Colors.WHITE,
        width: '100%'
    },
    childHr: {
        // height: 1,
        height: 0,
        backgroundColor: Colors.LIGHTGRAY,
        width: '100%',
    },
    padding: {
        height: 5,
        backgroundColor: Colors.RED,
        opacity: 0,
        width: '100%',
    },
    hr: {
        height: 1,
        backgroundColor: Colors.LIGHTGRAY,
        opacity: 1,
        width: '70%',
    },
    colorActive: {
        borderColor: Colors.GREEN,
    },
    colorInActive: {
        borderColor: Colors.DARKGRAY,
    },
    itemInfoBottom: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    itemStyles: {
        flexDirection: 'row',
        alignItems: 'space-between',
    },
    roomTitle: {
        fontSize: 18,
        fontWeight: 'normal',
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight",
        paddingLeft: 10,
        paddingRight: 5
    },
    itemCount: {
        fontSize: 18,
        fontWeight: 'normal',
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight",
        paddingLeft: 10,
        paddingRight: 10
    },
    itemStyleText: {
        fontSize: 14,
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight",
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2
    },
    itemIndex: {
        alignItems: 'flex-left',
        fontSize: 14,
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight",
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    itemName: {
        alignItems: 'center',
        fontSize: 15,
        color: Colors.DARKGRAY,
        fontFamily: "LatoLight",
        paddingBottom: 10,
        paddingLeft: 4,
        paddingRight: 20
    },
    itemInfoTop: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    itemCategory: {
        fontSize: 18,
        color: Colors.MEDGRAY,
        fontFamily: "LatoLight",
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 20
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 1,
    },

    buttonView: {
        // position: 'absolute',
        // bottom: 0,
        height: 32,
        width: 120,
        backgroundColor: Colors.RED,
        borderRadius: 16,
        // borderBottomLeftRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        // ...roomStyles.shadow
        // paddingRight: 2,
    },
    buttonText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white',
        fontFamily: "LatoLight",
        paddingLeft: 0,
        paddingRight: 0
    }
});

export default { 
    logo: logoStyles, 
    common: commonStyles, 
    filter: filterStyles, 
    room: roomStyles, 
    body: bodyStyles,
    heart: heartStyles
};