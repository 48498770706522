export const Colors = {
    PRIMARY:'#aa9ddd',

    WHITE:'#ffffff',
    GREEN:'#ff35a9',
    ORANGE: '#ff6500',
    RED: '#d02029',


    GRAY:'#f7f7f7',
    LIGHTGRAY: '#C7C7C7',
    MEDGRAY: '#999999',
    DARKGRAY: '#5E5E5E',
    CGRAY: '#feefee',
    ACTIVE: '#dad9ff',
    OFFLINE_GRAY: '#535353',

    BODY_HEADER: '#efefef',
    BODY: '#efefef',
    ROOM: '#efefef',
    ROOM_ACTIVE: '#efefef',
    FILTER: '#efefef'

    // BODY_HEADER: '#efefef',
    // BODY: '#efefef',
    // ROOM: '#fefefe',
    // ROOM_ACTIVE: '#eeeeee',
    // FILTER: '#fefefe'
}

interface ColorTheme {
    primary: string;
    onPrimary: string;
    surface: string;
    onSurface: string;
    background: string;
}


export const ThemeA: ColorTheme = {
    primary: '#c3c9fa',
    onPrimary: '#fff',
    surface: '#fff',
    onSurface: '#000',
    background: '#dedede',
};
  
export const Themes = {

    LightTheme : {
        PRIMARY:'#1abc9c',

        BACKGROUND:'#ffffff',
        LIGHTGREEN:'#BABABA',
        GREEN:'#0da935',
    
    
        GRAY:'#f7f7f7',
        LIGHTGRAY: '#C7C7C7',
        DARKGRAY: '#5E5E5E',
        CGRAY: '#ececec',
        OFFLINE_GRAY: '#535353'
        },

    DarkTheme : {
        PRIMARY:'#1abc9c',

        WHITE:'#ffffff',
        LIGHTGREEN:'#BABABA',
        GREEN:'#0da935',
    
    
        GRAY:'#f7f7f7',
        LIGHTGRAY: '#C7C7C7',
        DARKGRAY: '#5E5E5E',
        CGRAY: '#ececec',
        OFFLINE_GRAY: '#535353'
    }
}