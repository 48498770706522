import React, { Component } from 'react';
import { TouchableOpacity, FlatList, View, Text, DeviceEventEmitter } from 'react-native';
import Categories from './Categories'
import { Colors } from '../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircle as farCircle, faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';

import myStyles from '../styles/myStyles';

import furnitureStyles from '../data/vs.furnitureStyles.json';

export default class Filters extends Component {

	constructor(props) {
		super(props);
		this.state = {
			furnitureStyles: furnitureStyles,
			prevItem: null,
			wideness: props.wideness,
			filterClickedParent: props.filterClicked,
			menuClickedParent: props.menuClicked,
			sortMethod: props.sortMethod,
			sortExpanded: false,
			// sortClickedParent: props.sortClicked
		}
	}

	filterEventListener = null
	itemCountListener = () => {
		if(this.filterEventListener) this.filterEventListener.remove();
		this.filterEventListener = DeviceEventEmitter.addListener("filterEvent", (event)=>{
			this.filterEventListener.remove();
			this.setState({itemCount: event.itemCount});
		});
	}

	// If a filter is clicked
	filterClicked = (styleIndex, flag) => {
		const selected = flag == null ? !this.state.furnitureStyles[styleIndex].selected : null;
		this.state.furnitureStyles[styleIndex].selected = selected;
        let stylesSelected = [];
        for(const furnitureStyle of this.state.furnitureStyles) {
            if(furnitureStyle.selected) {
                stylesSelected.push(furnitureStyle.name);
            }
		}
		// filterClicked calculates the filtered item count and then emits and event
		this.props.filterClickedParent(stylesSelected);
		this.setState({stylesSelected: stylesSelected});

		// this.itemCountListener();
		// this.forceUpdate();
	}

	doneClicked = () => {
		this.props.showFilterClickedParent();
	}

	clearClicked = () => {
        for(const furnitureStyle of this.state.furnitureStyles) {
            furnitureStyle.selected && (furnitureStyle.selected = false);
        }
		this.props.filterClickedParent([]);
		this.setState({stylesSelected: []});
		this.itemCountListener();
		// this.state.stylesSelected = 0;
	}

	showSortMethodClicked = () => {
		this.setState({sortExpanded: !this.state.sortExpanded});
	}

	sortByNewestClicked = () => {
		this.setState({sortMethod: "Newest"});
		this.props.sortClickedParent("Newest");
	}

	sortByOldestClicked = () => {
		this.setState({sortMethod: "Oldest"});
		this.props.sortClickedParent("Oldest");
	}

	sortByPopularityClicked = () => {
		this.setState({sortMethod: "Popularity"});
		this.props.sortClickedParent("Popularity");
	}

	render() {
		const ShowIcon = ({ icon, size, color, text, style, show }) => {
			if (show) {
				return (
					<View style={style}>
						<FontAwesomeIcon icon={icon} size={size} color={color} />
						<Text style={myStyles.common.menuText}>{text}</Text>
					</View>
				)
			} else {
				return (
					<View></View>
				)
			}
		}

		this.itemCountListener();
		// this.defaultStyles();
		// if (!(this.props.roomExpanded || this.props.showFilter || this.state.itemCount > 0)) {
		// 	console.log("filter returning null")
		// 	return null;
		// } else {
		// 	console.log("showing filter")
		// }
		if(!this.props.show)  return null;
		return (
			<View>
				{/* <View style={myStyles.filter.header}></View> */}
				<View style={myStyles.filter.header}>
					<View style={myStyles.filter.header}>
						<Text style={[myStyles.common.roomTitle,{paddingRight:2}]}>FILTERS</Text>
						<Text style={[myStyles.common.roomTitle,{paddingLeft:2}]}>
							{this.state?.stylesSelected?.length>0 && ( '('+this.state.stylesSelected.length +')') }
						</Text>
					</View>
					{ this.state.itemCount > 0 && 
						<View style={myStyles.filter.header}>
							<Text style={myStyles.common.roomTitle}>
								{(this.state.itemCount + ' items')}
							</Text>
						</View>
					}
				</View>
				<View style={myStyles.filter.childHr} />
				<TouchableOpacity onPress={() => this.showSortMethodClicked()} >
					<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
						<Text style={myStyles.filter.subHeadingSmall} >Sort by: {this.state.sortMethod}</Text>
						<View
							style={{paddingTop:10, paddingRight:10}}
						>
							{
								!this.state.sortExpanded && 
									<FontAwesomeIcon icon='plus' size={14} color={Colors.MEDGRAY} />
							}
							{
								this.state.sortExpanded && 
									<FontAwesomeIcon icon='minus' size={14} color={Colors.MEDGRAY} />
							}
						</View>
						{/* <Text style={myStyles.filter.subHeadingSmall} >+</Text> */}
					</View>
				</TouchableOpacity>
				{
					this.state.sortExpanded && 
					<View
						style={{paddingLeft: 10}}
					>
						<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
							<TouchableOpacity onPress={() => this.sortByNewestClicked()} >
								<Text style={myStyles.filter.subHeadingSmall} >Newest</Text>
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
							<TouchableOpacity onPress={() => this.sortByOldestClicked()} >
								<Text style={myStyles.filter.subHeadingSmall} >Oldest</Text>
							</TouchableOpacity>
						</View>
						<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
							<TouchableOpacity onPress={() => this.sortByPopularityClicked()} >
								<Text style={myStyles.filter.subHeadingSmall} >Popularity</Text>
							</TouchableOpacity>
						</View>
					</View>
				}
				<View style={myStyles.filter.childHr} />
				<View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingTop:20, paddingBottom:20 }}>
					<TouchableOpacity onPress={() => this.clearClicked()} >
						<View style={[myStyles.common.buttonView, {borderWidth: 0.5, borderColor: "#888888", backgroundColor: "#eeeeee", width: 90}]}>
							<Text style={[myStyles.common.buttonText, {color: Colors.RED}]} >CLEAR</Text>
							{/* <FontAwesomeIcon icon='angle-right' size='20' color='white' /> */}
						</View>
					</TouchableOpacity>
					{
						this.props.filterSelected &&
						<TouchableOpacity onPress={() => this.doneClicked()} >
							<View style={[myStyles.common.buttonView, {width: 90}]}>
								<Text style={myStyles.common.buttonText} >DONE</Text>
								{/* <FontAwesomeIcon icon='angle-right' size='20' color='white' /> */}
							</View>
						</TouchableOpacity>
					}
				</View>
				<View style={myStyles.filter.childHr} />
				<Text style={myStyles.filter.subHeadingLarge} >Styles</Text>
				<View style={myStyles.filter.childHr} />
				<View style={{}}>
					<FlatList
						data={this.state.furnitureStyles}
						numColumns={1}
						scrollEnabled={true}
						renderItem={({ item, index }) =>
							<View style={{ paddingRight: 0 }}>
								<TouchableOpacity
									style={[myStyles.filter.itemRow]}
									onPress={() => this.filterClicked(index)}
								>
									{!item.selected &&
										<ShowIcon icon={farCircle} size={16} color={Colors.MEDGRAY} style={myStyles.filter.icon} show={!item.selected} />
									}
									{item.selected &&
										<ShowIcon icon={farCheckCircle} size={16} color={Colors.MEDGRAY} style={myStyles.filter.icon} show={item.selected} />
									}
									<Text style={[myStyles.common.font, myStyles.common.itemInactive]} >{item.name}</Text>
								</TouchableOpacity>
								{/* <View style={myStyles.common.childHr} /> */}
							</View>
						} />
				</View>
			</View>
		);
	}
}
