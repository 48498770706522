import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform, DeviceEventEmitter } from 'react-native';
import { Layout } from '../constants/Layout';

const getWidthInfo = (screenWidth, screenHeight) => {
    // const screenWidth = this.props.myWindowDimensions.width;
    // const screenHeight = this.props.myWindowDimensions.height;
    let wideness = 0;
    if (Platform.OS != 'web') {
        wideness = 0;
    } else if (screenWidth > 1500) {
        wideness = 4;
    } else if (screenWidth > 1250) {
        wideness = 3;
    } else if (screenWidth > 1000) {
        wideness = 2;
    } else if (screenWidth > 750) {
        wideness = 1;
    }

    // index is wideness which is 0,1,2,3 or 4
    let widthInfo = {
        left: [0, Layout.menuWidth, Layout.menuWidth, Layout.menuWidth, Layout.menuWidth],
        right: [0, 0, Layout.filterWidth, Layout.filterWidth, Layout.filterWidth],
        body: [],
        width: screenWidth
        // columns: []
    }
    for (let i in widthInfo.left) {
        widthInfo.body[i] = screenWidth - widthInfo.left[i] - widthInfo.right[i];
        // widthInfo.columns[i] = Math.round(widthInfo.body[i]/Layout.itemContainerWidth-1) + 1;
    }

    return { w: widthInfo, i: wideness, h: screenHeight };
}


// onComplete is an object with a key called function and arguments inside a key called params
const localStorage = (key, value, action, onComplete, onError, end) => {

    const saveLocal = async () => {
        try {
            await AsyncStorage.setItem(key, value);
            onComplete ?.functions && onComplete.functions[0]("Item added");
        } catch (e) {
            onError && onError("Error adding item");
            console.error('Error adding item:', e);
        }
    }

    const deleteLocal = async () => {
        try {
            await AsyncStorage.removeItem(key);
            onComplete ?.functions && onComplete.functions[0]("Item removed");
        } catch (e) {
            onError && onError("Error removing item");
            console.error('Error removing item:', e);
        }
    }

    const readLocal = async (emit) => {
        let data;
        try {
            const value = await AsyncStorage.getItem(key);
            data = JSON.parse(value);
            if (value != null && emit == 'hearts') {
                DeviceEventEmitter.emit('heartEvent', { heartCount: data.length, hearts: data });
            }
            // Execute all functions if any
            if (onComplete ?.functions) {
                for (const f of onComplete.functions) {
                    f(data);
                }
            }
        } catch (e) {
            onError && onError("Error reading data");
            console.error('Error adding item:', e);
        } finally {
            end && end(data);
        }
    }
    if (action == 'save') saveLocal();
    if (action == 'delete') deleteLocal();
    if (action == 'read') readLocal(null);
    if (action == 'readHearts') readLocal('hearts');
}

const heartToggle = (item, hearts, op, index, config) => {
    if (item.heart) {
        // const operation = op || 'push';
        // if(operation == 'push') {
        // }
        if (op == 'splice' && index >= 0) {
            hearts.splice(index, 0, item)
        } else {
            hearts.push(item);
        }
        localStorage('hearts', JSON.stringify(hearts), 'save', null, null, null);
        DeviceEventEmitter.emit('heartEvent', { hearts: hearts, heartCount: hearts.length, event: "add" });
    } else {
        const index = hearts.map(function (x) { return x.name; }).indexOf(item.name);
        if (index > -1) {
            // Future - if delay - then delay writing to local storage so the item doesn't immediately disappear
            hearts.splice(index, 1);
            DeviceEventEmitter.emit('heartEvent', { hearts: hearts, heartCount: hearts.length, type: "delete", item: item, index: index });
            localStorage('hearts', JSON.stringify(hearts), 'save', null, null, null);
        }
    }
    return hearts;
    // update = false;
}


const parseConfig = {
    url: 'https://parse.3discrete.com:13307/',
    db: 'vscatalog/classes/',
    collection: 'favorites',
    headers: {
        'X-Parse-Application-Id': 'vsCatalog',
        'Content-Type': 'application/json',
    }
};

const readParse = async (parseId, emit, end) => {
    let json;
    try {
        const response = await fetch(
            parseConfig.url + parseConfig.db + parseConfig.collection + '/' + parseId, {
            method: 'get',
            headers: new Headers(parseConfig.headers)
        })

        json = await response.json();
        emit && DeviceEventEmitter.emit('parseEvent', { data: json, type: "read" });
    } catch (error) {
        console.error(error);
    } finally {
        end && end(json);
    }
};

const findParse = async (query, emit, end) => {
    let json;

    // const data = '?where={\\"' + query.key + '\\":\\"' + query.value + '\\"}';
    //              https://parse.3discrete.com:13307/vscatalog/classes/domains?where={\"domain\":\"localhost\"} 
    // const url = "https://parse.3discrete.com:13307/vscatalog/classes/favorites?where={\"vsid\":\"8Rk2WmCwWU\"}";

    // const data = '?where={\\"' + query.key + '\\":\\"' + query.value + '\\"}';
    const data = "?where={\"" + query.key + "\":\"" + query.value + "\"}";
    const url = parseConfig.url + parseConfig.db + query.collection + data;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: new Headers(parseConfig.headers),
        })
        json = await response.json();
        const event = emit ?.event || 'parseEvent';
        const type = emit ?.type || 'read';
        emit && DeviceEventEmitter.emit(event, { data: json, type: type });
    } catch (error) {
        console.error(error);
    } finally {
        end && json ?.results ?.length > 0 && end(json.results[0]);
    }
};

/*
    this site helped convert cURL to fetch
    https://kigiri.github.io/fetch/

    curl -X GET -H "X-Parse-Application-Id: vsCatalog" \
    -G --data-urlencode 'where={"vsid":"8QKf4dmsGC"}' \
    https://parse.3discrete.com:13307/vscatalog/classes/favorites

    curl -X GET -H "X-Parse-Application-Id: vsCatalog" \
    -G --data-urlencode 'where={"domain":"localhost"}' \
    https://parse.3discrete.com:13307/vscatalog/classes/domains

*/


const createParse = async (userData, emit, end) => {
    let json;
    try {
        const response = await fetch(
            parseConfig.url + parseConfig.db + parseConfig.collection, {
            method: 'post',
            headers: new Headers(parseConfig.headers),
            body: JSON.stringify(userData)
        })

        json = await response.json();
        emit && DeviceEventEmitter.emit('parseEvent', { data: json, type: "create" });
    } catch (error) {
        console.error(error);
    } finally {
        end && end(json);
    }
};

const updateParse = async (userData, parseId, emit, end) => {
    let json;
    try {
        const response = await fetch(
            parseConfig.url + parseConfig.db + parseConfig.collection + '/' + parseId, {
            method: 'put',
            headers: new Headers(parseConfig.headers),
            body: JSON.stringify(userData)
        })

        json = await response.json();
        emit && DeviceEventEmitter.emit('parseEvent', { data: json, type: "update" });
    } catch (error) {
        console.error(error);
    } finally {
        end && end(json);
    }
};


const readRemoteJSONNEW = (remoteJSON, emit, end) => {
    fetch(remoteJSON)
        .then(response => {
            console.log(response);
            response.json();
        })
        .then(responseJson => {
        })
}

const readRemoteJSON = async (remoteJSON, emit, end) => {
    let json;

    // headers.append('Access-Control-Allow-Origin', 'http://localhost:19006');
    try {
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        const response = await fetch(remoteJSON, {
            method: "GET",
            headers: headers,
            mode: "cors",
        });

        json = await response.json();
        emit && DeviceEventEmitter.emit('remoteJSON', { data: json, type: "read" });
    } catch (error) {
        console.error(error);
    } finally {
        end && end(json);
    }
};


const base62 = {
    charset: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
        .split(''),
    encode: integer => {
        if (integer === 0) {
            return 0;
        }
        let s = [];
        while (integer > 0) {
            s = [base62.charset[integer % 62], ...s];
            integer = Math.floor(integer / 62);
        }
        return s.join('');
    },
    decode: chars => chars.split('').reverse().reduce((prev, curr, i) =>
        prev + (base62.charset.indexOf(curr) * (62 ** i)), 0)
};


const uuid = (
    h = 16,
    s = s => Math.floor(s).toString(h)
) =>
    s(Date.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(Math.random() * h));


const objectId = () => {
    const d = Date.now();
    const r = Math.round(Math.random() * 1e12);
    const o = base62.encode(d + r);
    return o;
}

const objectIdOld = (numChars) => {
    const d1 = Date.now() * Math.pow(5, numChars);
    const d2 = Date.now() * Math.pow(6, numChars);
    const o1 = base62.encode(d1);
    const o2 = base62.encode(d2);
    const o3 = o1 + '' + o2;
    return o3.substr(o3.length - numChars, numChars);
}

export default {
    localStorage: localStorage,
    getWidthInfo: getWidthInfo,
    heartToggle: heartToggle,
    parse: {
        config: parseConfig,
        read: readParse,
        create: createParse,
        update: updateParse,
        find: findParse
    },
    readRemoteJSON: readRemoteJSON,
    uuid: uuid,
    objectId: objectId
}









/*

curl -X POST -H \
"X-Parse-Application-Id: vsCatalog" \
-H "Content-Type: application/json" \
-d '{"score":1337,"playerName":"Sean Plott","cheatMode":false}' \
https://parse.3discrete.com:13307/vscatalog/classes/GameScore


curl -X GET -H "X-Parse-Application-Id: vsCatalog" \
https://parse.3discrete.com:13307/vscatalog/classes/GameScore/Jis7SDnZj2


curl -X PUT -H \
"X-Parse-Application-Id: vsCatalog" \
-H "Content-Type: application/json" \
-d '{"score":1337,"playerName":"Parag Tope","cheatMode":false}' \
https://parse.3discrete.com:13307/vscatalog/classes/GameScore/SNOAEW4NES


curl -X POST \
-H "X-Parse-Application-Id: vsCatalog" \
-G \
--data-urlencode 'where={"vsid":"8OzxCT7GOE"}' \
https://parse.3discrete.com:13307/vscatalog/classes/favorites




curl -X POST -H \
"X-Parse-Application-Id: vsCatalog" \
-H "Content-Type: application/json" \
-d @'items/items.json' \
https://parse.3discrete.com:13307/vscatalog/classes/items

*/