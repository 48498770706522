const availableRooms = [
 {
  "name": "Any Room",
  "types": [
   {
    "type": "Area Rug",
    "suffixes": "AD",
    "id": "arearug"
   },
   {
    "type": "Art (Wall)",
    "suffixes": "AC",
    "id": "artwall"
   },
   {
    "type": "Books",
    "suffixes": "AG,SI",
    "id": "books"
   },
   {
    "type": "Bookshelf",
    "suffixes": "AP",
    "id": "bookshelf"
   },
   {
    "type": "Ceiling fan",
    "suffixes": "AT",
    "id": "ceilingfan"
   },
   {
    "type": "Collection",
    "suffixes": "SJ",
    "id": "collection"
   },
   {
    "type": "Decorative (Floor)",
    "suffixes": "AN",
    "id": "decorativefloor"
   },
   {
    "type": "Decorative (Surface)",
    "suffixes": "AH,SB,SH",
    "id": "decorativesurface"
   },
   {
    "type": "Decorative (Wall)",
    "suffixes": "AJ,AK,WD",
    "id": "decorativewall"
   },
   {
    "type": "Fauna",
    "suffixes": "AS",
    "id": "fauna"
   },
   {
    "type": "Fire",
    "suffixes": "AR",
    "id": "fire"
   },
   {
    "type": "Frame (Wall)",
    "suffixes": "WA",
    "id": "framewall"
   },
   {
    "type": "Lighting",
    "suffixes": "AA,SA",
    "id": "lighting"
   },
   {
    "type": "Musical Instrument",
    "suffixes": "AL",
    "id": "musicalinstrument"
   },
   {
    "type": "Partition/Screen",
    "suffixes": "AF",
    "id": "partitionscreen"
   },
   {
    "type": "Pillow",
    "suffixes": "AM",
    "id": "pillow"
   },
   {
    "type": "Plant (Floor)",
    "suffixes": "PC",
    "id": "plantfloor"
   },
   {
    "type": "Plant (Hanging)",
    "suffixes": "PG",
    "id": "planthanging"
   },
   {
    "type": "Plant (Surface)",
    "suffixes": "PB,PD",
    "id": "plantsurface"
   },
   {
    "type": "Plant (Wall)",
    "suffixes": "PF",
    "id": "plantwall"
   },
   {
    "type": "TV",
    "suffixes": "AI",
    "id": "tv"
   },
   {
    "type": "Wall Mirror",
    "suffixes": "AB",
    "id": "wallmirror"
   },
   {
    "type": "Window Curtain",
    "suffixes": "AE",
    "id": "windowcurtain"
   },
   {
    "type": "Other",
    "suffixes": "AZ,PI,PZ,SZ,WZ",
    "id": "other"
   }
  ],
  "id": "anyroom"
 },
 {
  "name": "Bath",
  "types": [
   {
    "type": "Bath Accessory",
    "suffixes": "TC",
    "id": "bathaccessory"
   },
   {
    "type": "Bath Ensemble",
    "suffixes": "TB",
    "id": "bathensemble"
   },
   {
    "type": "Towel",
    "suffixes": "TA",
    "id": "towel"
   },
   {
    "type": "Undefined",
    "suffixes": "TX",
    "id": "undefined"
   },
   {
    "type": "Other",
    "suffixes": "TZ",
    "id": "other"
   }
  ],
  "id": "bath"
 },
 {
  "name": "Bedroom",
  "types": [
   {
    "type": "Armoire",
    "suffixes": "BB",
    "id": "armoire"
   },
   {
    "type": "Bed",
    "suffixes": "BA",
    "id": "bed"
   },
   {
    "type": "Bed set",
    "suffixes": "BG",
    "id": "bedset"
   },
   {
    "type": "Bedding",
    "suffixes": "BH",
    "id": "bedding"
   },
   {
    "type": "Bench",
    "suffixes": "BK",
    "id": "bench"
   },
   {
    "type": "Chest",
    "suffixes": "BD",
    "id": "chest"
   },
   {
    "type": "Crib",
    "suffixes": "BJ",
    "id": "crib"
   },
   {
    "type": "Dresser",
    "suffixes": "BC",
    "id": "dresser"
   },
   {
    "type": "Murphy",
    "suffixes": "BM",
    "id": "murphy"
   },
   {
    "type": "Nightstand",
    "suffixes": "BE",
    "id": "nightstand"
   },
   {
    "type": "Other",
    "suffixes": "BI,BZ",
    "id": "other"
   }
  ],
  "id": "bedroom"
 },
 {
  "name": "Dining",
  "types": [
   {
    "type": "Barstool",
    "suffixes": "DG",
    "id": "barstool"
   },
   {
    "type": "Buffet Table",
    "suffixes": "DE",
    "id": "buffettable"
   },
   {
    "type": "Ceiling Light",
    "suffixes": "DJ",
    "id": "ceilinglight"
   },
   {
    "type": "Chair",
    "suffixes": "DM",
    "id": "chair"
   },
   {
    "type": "China Cabinet",
    "suffixes": "DD",
    "id": "chinacabinet"
   },
   {
    "type": "Dining Set",
    "suffixes": "DH,DI",
    "id": "diningset"
   },
   {
    "type": "Dining Table",
    "suffixes": "DA",
    "id": "diningtable"
   },
   {
    "type": "Place Setting",
    "suffixes": "DL",
    "id": "placesetting"
   },
   {
    "type": "Server",
    "suffixes": "DF",
    "id": "server"
   },
   {
    "type": "Other",
    "suffixes": "DK,DZ",
    "id": "other"
   }
  ],
  "id": "dining"
 },
 {
  "name": "Game/Media",
  "types": [
   {
    "type": "Arcade",
    "suffixes": "GB",
    "id": "arcade"
   },
   {
    "type": "Chair",
    "suffixes": "GI",
    "id": "chair"
   },
   {
    "type": "Dart",
    "suffixes": "GC",
    "id": "dart"
   },
   {
    "type": "Exercise Equipment",
    "suffixes": "GF",
    "id": "exerciseequipment"
   },
   {
    "type": "Pool Table",
    "suffixes": "GA",
    "id": "pooltable"
   },
   {
    "type": "Sport Equipment",
    "suffixes": "GG",
    "id": "sportequipment"
   },
   {
    "type": "Other",
    "suffixes": "GE,GZ",
    "id": "other"
   }
  ],
  "id": "gamemedia"
 },
 {
  "name": "Kids",
  "types": [
   {
    "type": "Beanbag",
    "suffixes": "CD",
    "id": "beanbag"
   },
   {
    "type": "Bed",
    "suffixes": "CE",
    "id": "bed"
   },
   {
    "type": "Furniture",
    "suffixes": "CC",
    "id": "furniture"
   },
   {
    "type": "Toy",
    "suffixes": "CA,CB",
    "id": "toy"
   },
   {
    "type": "Other",
    "suffixes": "CZ",
    "id": "other"
   }
  ],
  "id": "kids"
 },
 {
  "name": "Kitchen",
  "types": [
   {
    "type": "Appliance",
    "suffixes": "KG",
    "id": "appliance"
   },
   {
    "type": "Cabinet",
    "suffixes": "KF",
    "id": "cabinet"
   },
   {
    "type": "Countertop Item",
    "suffixes": "KB",
    "id": "countertopitem"
   },
   {
    "type": "Fruit/Food/Drink",
    "suffixes": "KD",
    "id": "fruitfooddrink"
   },
   {
    "type": "Island",
    "suffixes": "KA",
    "id": "island"
   },
   {
    "type": "Tool/Gadget",
    "suffixes": "KE",
    "id": "toolgadget"
   },
   {
    "type": "Wall Item",
    "suffixes": "KC",
    "id": "wallitem"
   },
   {
    "type": "Other",
    "suffixes": "KZ",
    "id": "other"
   }
  ],
  "id": "kitchen"
 },
 {
  "name": "Living",
  "types": [
   {
    "type": "Bench",
    "suffixes": "LS",
    "id": "bench"
   },
   {
    "type": "Chair",
    "suffixes": "LC",
    "id": "chair"
   },
   {
    "type": "Cocktail Table",
    "suffixes": "LG",
    "id": "cocktailtable"
   },
   {
    "type": "Coffee Table",
    "suffixes": "LH",
    "id": "coffeetable"
   },
   {
    "type": "Console Table",
    "suffixes": "LN",
    "id": "consoletable"
   },
   {
    "type": "End Table",
    "suffixes": "LI",
    "id": "endtable"
   },
   {
    "type": "Lounge Chair",
    "suffixes": "LT",
    "id": "loungechair"
   },
   {
    "type": "Media Console",
    "suffixes": "LJ",
    "id": "mediaconsole"
   },
   {
    "type": "Modular Seating",
    "suffixes": "LQ",
    "id": "modularseating"
   },
   {
    "type": "Ottoman",
    "suffixes": "LF",
    "id": "ottoman"
   },
   {
    "type": "Sectional",
    "suffixes": "LE",
    "id": "sectional"
   },
   {
    "type": "Set",
    "suffixes": "LM",
    "id": "set"
   },
   {
    "type": "Sofa (Large)",
    "suffixes": "LA",
    "id": "sofalarge"
   },
   {
    "type": "Sofa (Small)",
    "suffixes": "LB",
    "id": "sofasmall"
   },
   {
    "type": "Tv Stand",
    "suffixes": "LP",
    "id": "tvstand"
   },
   {
    "type": "Wall System",
    "suffixes": "LK",
    "id": "wallsystem"
   },
   {
    "type": "Other",
    "suffixes": "LO,LZ",
    "id": "other"
   }
  ],
  "id": "living"
 },
 {
  "name": "Office",
  "types": [
   {
    "type": "Bookcase",
    "suffixes": "OC",
    "id": "bookcase"
   },
   {
    "type": "Computer Desk",
    "suffixes": "OA",
    "id": "computerdesk"
   },
   {
    "type": "Copier",
    "suffixes": "OG",
    "id": "copier"
   },
   {
    "type": "Desk Chair",
    "suffixes": "OB",
    "id": "deskchair"
   },
   {
    "type": "File Cabinet",
    "suffixes": "OD",
    "id": "filecabinet"
   },
   {
    "type": "Files",
    "suffixes": "OL",
    "id": "files"
   },
   {
    "type": "Filing Cabinet",
    "suffixes": "ON",
    "id": "filingcabinet"
   },
   {
    "type": "Gadget/Laptop",
    "suffixes": "OJ",
    "id": "gadgetlaptop"
   },
   {
    "type": "Office Chair",
    "suffixes": "OQ",
    "id": "officechair"
   },
   {
    "type": "Office Table",
    "suffixes": "OP",
    "id": "officetable"
   },
   {
    "type": "Trashcan",
    "suffixes": "OI",
    "id": "trashcan"
   },
   {
    "type": "Vending Machine",
    "suffixes": "OO",
    "id": "vendingmachine"
   },
   {
    "type": "Wall Board",
    "suffixes": "OM",
    "id": "wallboard"
   },
   {
    "type": "Water cooler",
    "suffixes": "OK",
    "id": "watercooler"
   },
   {
    "type": "Other",
    "suffixes": "OF,OZ",
    "id": "other"
   }
  ],
  "id": "office"
 },
 {
  "name": "Outdoor",
  "types": [
   {
    "type": "Bench and Table",
    "suffixes": "XH",
    "id": "benchandtable"
   },
   {
    "type": "Chair",
    "suffixes": "XE",
    "id": "chair"
   },
   {
    "type": "Decorative",
    "suffixes": "XK",
    "id": "decorative"
   },
   {
    "type": "End Table",
    "suffixes": "XJ",
    "id": "endtable"
   },
   {
    "type": "Fence",
    "suffixes": "XI",
    "id": "fence"
   },
   {
    "type": "Patio Chair",
    "suffixes": "XB",
    "id": "patiochair"
   },
   {
    "type": "Patio Set",
    "suffixes": "XD",
    "id": "patioset"
   },
   {
    "type": "Patio Table",
    "suffixes": "XA",
    "id": "patiotable"
   },
   {
    "type": "Patio Umbrella",
    "suffixes": "XC",
    "id": "patioumbrella"
   },
   {
    "type": "Seating",
    "suffixes": "XG",
    "id": "seating"
   },
   {
    "type": "Other",
    "suffixes": "XF",
    "id": "other"
   }
  ],
  "id": "outdoor"
 },
 {
  "name": "Uncategorized",
  "types": [
   {
    "type": "Ruler",
    "suffixes": "XR",
    "id": "ruler"
   },
   {
    "type": "Washer/Dryer",
    "suffixes": "XW",
    "id": "washerdryer"
   },
   {
    "type": "Other",
    "suffixes": "XZ",
    "id": "other"
   }
  ],
  "id": "uncategorized"
 }
];

export default availableRooms;