import React, { Component } from 'react';
import { View, TouchableOpacity, Text, FlatList, StyleSheet, LayoutAnimation, Platform, UIManager } from "react-native";
import { Colors } from '../constants/Colors';
import Icon from "react-native-vector-icons/MaterialIcons";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import myStyles from '../styles/myStyles';

export default class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            categories: props.categories,
            expanded: null,
            // room: null,
            // room: null,
            category: null,
        }

        if (Platform.OS === 'android') {
            UIManager.setLayoutAnimationEnabledExperimental(true);
        }
    }

    room = null

    componentDidMount = () => {
        // console.log("Categories -> componentDidMount -> props.catalogRoom and index:", this.props.catalogRoom, this.props.index);
        if( this.props.catalogRoom?.expanded && this.props.index == this.props.catalogRoom.index ) {
            this.setState({room: this.props.catalogRoom});
            this.room = this.props.catalogRoom;
            this.categoryClicked(this.props.catalogRoom.categoryIndex, this.props.catalogRoom, true);
        } 
    }

    roomClicked = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);

        const expanded = !this.room?.expanded;  // This is synchronous - read the value
        this.room = {index: this.state.index, expanded: expanded, categoryIndex: this.state.category};
        if(this.room.expanded) {
            this.categoryClicked(null, this.room, false, false);
        }
        // Send the synchronous state to parent
        // So that the room info can be used to render the items - which is a peer component
        this.props.roomClickedParent(this.room);

    }

    categoryClicked = (index, room, value, callParent) => {

        // Clone the array of accordion states and then update the state with the modified clone
        const categoriesCopy = this.props.categories.slice();

        // Unselect all categories 
        for (const [i, c] of categoriesCopy.entries()) {
            if(i != index) {
                c.value = false;
            }
        }

        if(index != null) {
            const categorySelected = value == 'toggle' ? !categoriesCopy[index].value : value;
            categoriesCopy[index].value = categorySelected;
            room.categoryIndex = categorySelected ? index : null;
            this.setState({room: room});
            // Notify the "Room" component
            if(callParent) this.props.categoryClickedParent(room);
        }

        this.setState({ categories: categoriesCopy });
    }

    render() {

        // console.log("Categories -> render: index:", this.state.index, this.props?.catalogRoom?.index)
        // This unexpands the previously expanded accordion
        if(this.room?.expanded && this.props?.catalogRoom?.expanded && this.state.index != this.props?.catalogRoom?.index) {
            this.room.expanded = false;
        }
        // console.log("Categories -> render: Rroom:", this.room);
        // console.log("Categories -> render: Croom:", this.props?.catalogRoom);

        const FONT_ROOM_X = 21;
        const FONT_TYPE_X = 21;

        const ShowIcon = ({icon, size, color, show}) => {
            if (show) {
                return (
                    <FontAwesomeIcon icon={icon} size={size} color={color} />
                )
            } else {
                return (
                    <View></View>
                )
            }
        }
        return (
            <View>
                {/* This button is the room */}
                <TouchableOpacity style={[myStyles.room.row, this.room?.expanded ? myStyles.room.btnActive : myStyles.common.btnInactive]} onPress={() => this.roomClicked()}>
                    <Text style={[myStyles.common.title]}>{this.props.name}</Text>
                    {/* <ShowIcon icon={'angle-down'} size={FONT_ROOM_X} color={Colors.DARKGRAY} show={this.room?.expanded}/> */}
                    {
                        !this.room?.expanded && false && 
                        <ShowIcon icon={'plus'} size={FONT_ROOM_X} color={Colors.LIGHTGRAY} show={!this.room?.expanded}/>
                    }
                    {
                        this.room?.expanded &&
                        <ShowIcon icon={'minus'} size={FONT_ROOM_X} color={Colors.LIGHTGRAY} show={this.room?.expanded}/>
                    }
                </TouchableOpacity>
                <View style={myStyles.common.parentHr} />

                {/* These buttons in the flatlist are the categories */}
                {
                    this.room?.expanded &&
                    <View style={{}}>
                        <FlatList
                            data={this.state.categories}
                            numColumns={1}
                            scrollEnabled={true}
                            renderItem={({ item, index }) =>
                                <View style={{ paddingRight: 0 }}>
                                    <TouchableOpacity 
                                        style={[myStyles.room.childRow, myStyles.common.button, item.value ? myStyles.room.btnActive : myStyles.common.btnInactive]} 
                                        onPress={() => this.categoryClicked(index, this.room, 'toggle', true)}
                                    >
                                        <Text style={[myStyles.common.font, myStyles.common.itemInactive]} >{item.type}</Text>
                                        <ShowIcon icon={'check'} size={FONT_TYPE_X} color={Colors.MEDGRAY}  show={item.value}/>
                                    </TouchableOpacity>
                                    <View style={myStyles.common.childHr} />
                                </View>
                            } />
                    </View>
                }
            </View>
        )
    }
}
