import React, { Component } from 'react';
import VS from '../components/VS';
import { Colors } from '../constants/Colors';
import { TouchableOpacity, View } from 'react-native';
import myStyles from '../styles/myStyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import VsItems from '../screens/Items';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <View
                style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}
            >
                <VS.Logo paddingLeft={30}/>

                <View
                    style={myStyles.common.icon}
                >
                    <TouchableOpacity
                        style={[myStyles.common.menu]}
                        onPress={this.props.browseClickedParent}
                    >
                        <FontAwesomeIcon icon={'magnifying-glass'} size={18} color={Colors.LIGHTGRAY} />
                        {/* <Text style={[myStyles.common.menuText, {color: Colors.MEDGRAY}]}>Browse</Text> */}
                    </TouchableOpacity>
                </View>

                {/* <ShowIcon.Room
                    icon={'magnifying-glass'}
                    press={this.props.browseClickedParent}
                    size={21}
                    color={Colors.DARKGRAY}
                    show={true}
                    text={'Browse'}
                /> */}
            </View>
        )
    }
}

export default Header