import React, { Component } from 'react';
import { Text, TextInput, View, DeviceEventEmitter, LayoutAnimation, TouchableOpacity, useWindowDimensions } from 'react-native';
import {
    SafeAreaProvider,
} from 'react-native-safe-area-context';
import { Colors } from '../constants/Colors';
import { Layout } from '../constants/Layout';
import myStyles from '../styles/myStyles';
import VS from '../components/VS';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { StackActions } from '@react-navigation/native';

import AsyncStorage from '@react-native-async-storage/async-storage';

import utils from '../utils/utils';

import Rooms from './Rooms';
import Filters from './Filters';
import VsItems from './Items';
// import itemArr from '../data/vs.items.json';

import availableRooms from '../data/vs.availableRooms';
import ShowIcon from '../components/ShowIcon';

// https://stackoverflow.com/questions/59137220/react-native-navigation-moving-between-routes-on-same-screen-with-different-pa
// https://stackoverflow.com/questions/59600773/navigate-to-same-screen-with-different-parameters-using-reactnavigation-reac
// this.props.navigation.setParams()

const withWindowDimensions = Component => props => {
    const myWindowDimensions = useWindowDimensions();
    return <Component {...props} myWindowDimensions={myWindowDimensions} />;
};

// Manually control the renderer because it was forcing a rerender of the
// entire flatlist even when only an item was hearted
let update = false;
let searchTextGlobal = '';

const USE_NAVIGATION = false;

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
        contentSize.height - paddingToBottom;
};

const initRoom = (routeParams, caller) => {
    // console.log("initroom called", caller, routeParams);
    let indexCategory;
    const indexRoom = availableRooms.map(function (x) { return x.id; }).indexOf(routeParams ?.room);
    if (indexRoom >= 0 && routeParams ?.category) {
        indexCategory = availableRooms[indexRoom].types.map(function (x) { return x.id; }).indexOf(routeParams ?.category);
    }

    // console.log("routeParams", routeParams?.room, indexRoom, indexCategory);
    if (USE_NAVIGATION) {
        return {
            index: indexRoom >= 0 ? indexRoom : null,
            categoryIndex: indexCategory >= 0 ? indexCategory : null,
            expanded: indexRoom >= 0
        }
    } else {
        return null;
    }
}

// const ShowIcon = ({ icon, size, state, colorActive, colorInactive, text, press, show }) => {
//     if (show) {
//         return (
//             <View
//                 style={myStyles.common.icon}
//             >
//                 <TouchableOpacity
//                     style={{
//                         flexDirection: 'row',
//                         justifyContent: 'flex-start',
//                         height: 50,
//                         paddingRight: 18,
//                         alignItems: 'center',
//                         // backgroundColor: backgroundColor,
//                         paddingLeft: 10
//                     }}
//                     onPress={() => press()}
//                 >
//                     <FontAwesomeIcon icon={icon} size={size} color={state ?.room ?.expanded ? colorActive : colorInactive} />
//                     <Text style={[myStyles.common.title]}>{text}</Text>
//                 </TouchableOpacity>
//             </View>
//         )
//     } else {
//         return (
//             <View></View>
//         )
//     }
// }

class HeartCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            heartCount: props.heartCount
        }
    }

    heartCountListener = () => {
        const heartEventListener = DeviceEventEmitter.addListener("heartEvent", (event) => {
            // console.log("heartEventListener:", event.heartCount);
            this.setState({ heartCount: event.heartCount });
            heartEventListener.remove();
            this.forceUpdate();
        });
    }

    render() {
        this.heartCountListener();
        // console.log("rendering heartcart");
        if (this.props.show) {
            return (
                <View>
                    <TouchableOpacity
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            height: 50,
                            left: this.props.showFilterColumn ? 0 : -16,
                            paddingRight: 18,
                            alignItems: 'center',
                            // backgroundColor: backgroundColor,
                            paddingLeft: 10,
                            // width: 50
                        }}
                        onPress={() => this.props.press()}
                    >
                        <FontAwesomeIcon
                            icon={farHeart}
                            size={27}
                            color={Colors.MEDGRAY}
                        />
                        {
                            this.state.heartCount > 0 &&
                            <View
                                style={{
                                    position: 'absolute',
                                    top: 20,
                                    right: 27
                                }}
                            >
                                <View
                                    style={{
                                        borderWidth: 0,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 22,
                                        position: 'absolute',
                                        height: 22,
                                        backgroundColor: Colors.RED,
                                        borderRadius: 11,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 15,
                                            color: Colors.WHITE
                                        }}
                                    >{this.state.heartCount}</Text>

                                </View>
                            </View>
                        }
                    </TouchableOpacity>
                </View>
            )
        } else {
            return (
                <View></View>
            )
        }
    }
}

class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        }
    }
    onChangeText = (searchText) => {
        this.state.searchText = searchText;
        // searchTextGlobal allows the text to remain the same between the two input boxes
        searchTextGlobal = searchText;
        DeviceEventEmitter.emit('searchTextEvent', { searchText: searchText });
        // update = true;
        this.forceUpdate();
    }

    clearText = () => {
        this.state.searchText = '';
        searchTextGlobal = '';
        DeviceEventEmitter.emit('searchTextEvent', { searchText: searchTextGlobal });
        this.forceUpdate();
    }

    render() {
        if (this.state.searchText != searchTextGlobal) {
            this.state.searchText = searchTextGlobal;
        }

        const inputTextWidth = this.props.bodyWidth
            // - (this.props.showLogo ? (Layout.logoWidth+45) : 4)
            // - (this.props.row == 1 ? 0 : 0)
            - (this.props.showHeartCart ? 60 : 0);
        // const left = this.props.showLogo ? -25 : 0;
        const viewWidth = inputTextWidth + 22;
        const left = 0;
        if (this.props.show) {
            return (
                <View style={{ width: viewWidth, flexDirection: 'row', justifyContent: 'center', left: left }}>
                    <TextInput
                        style={[myStyles.common.input, { width: inputTextWidth }]}
                        placeholder="Search by item id, room, category, style ..."
                        onChangeText={this.onChangeText}
                        value={this.state.searchText}
                    />
                    <View
                        style={{ left: -30, top: 14 }}
                    >
                        {
                            this.state.searchText.length == 0 &&
                            <FontAwesomeIcon icon={'magnifying-glass'} size={21} color={Colors.MEDGRAY} />
                        }
                        {
                            this.state.searchText.length > 0 &&
                            <TouchableOpacity onPress={() => this.clearText()}>
                                <FontAwesomeIcon icon={'xmark'} size={21} color={Colors.MEDGRAY} />
                            </TouchableOpacity>


                        }
                    </View>
                </View>
            )
        } else {
            return (
                <View>

                </View>
            )
        }
    }
}

class Catalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            WI: utils.getWidthInfo(props.myWindowDimensions.width, props.myWindowDimensions.height),
            room: initRoom(props.route.params, "catalog-room"),
            category: initRoom(props.route.params, "catalog-category"),
            // room: null,
            // category: null,
            menuSelected: false,
            filterSelected: false,
            sortMethod: "Newest",
            CW: {},
            stylesSelected: [],
            hearts: [],
            // itemCount: 0
        }

        if (USE_NAVIGATION) {
            this.props.navigation.addListener('blur', (e) => {
                // Prevent default action
            });

            this.props.navigation.addListener('focus', (e) => {
                // Prevent default action
                let room;
                if (props.route.path == '/browse') {
                    room = this.state.room;
                    room.expanded = false;

                } else {
                    room = initRoom(props.route.params, "focus-room");
                }
                this.setState({ room: room });
                // this.forceUpdate();
                // update = true;
                // e.preventDefault();
            });
        }

        // Read itemArr from remote JSON file
        utils.readRemoteJSON('https://48ta.visualstager.com/items/items.json', true, (response) => {
            this.itemArr = response;
            // After items are read - load the locally saved hearts and map them to the itemArr
            utils.localStorage('hearts', null, 'readHearts', { functions: [this.mapHeartsItems], params: null }, null, null);
        });

        this.heartCountListener();
    }

    heartEventListener = null;
    itemCount = null;
    itemArr = [];

    heartCountListener = () => {
        if (this.heartEventListener) this.heartEventListener.remove();
        this.heartEventListener = DeviceEventEmitter.addListener("heartEvent", (event) => {
            this.heartEventListener.remove();
            this.setState({ heartCount: event.heartCount });
            this.state.hearts = event.hearts;
        });
    }

    shouldComponentUpdate() {
        // If update - a global variable, has been set to false - set it to true then return false
        // This doesn't stop any future re-renders
        if (!update) {
            update = true;
            return false;
        }
        return true;
    }

    // If a room is clicked
    roomClicked = (room) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);

        this.setState({ room: room });
        if (USE_NAVIGATION) {
            // console.log("Catalog -> roomClicked -> room:", room);
            // const action = this.state.menuSelected ? "navigate" : "push";
            const action = this.state.menuSelected ? "navigate" : "push";
            this.props.navigation[action]('Catalog', {
                'room': room.expanded ? availableRooms[room.index].id : ""
            });
        }

        update = true;
    }

    categoryClicked = (room, caller) => {
        // console.log("category clicked:", room);
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
        // //const category = availableRooms[room.index]?.types[room.categoryIndex];

        if (USE_NAVIGATION) {
            this.props.navigation.push('Catalog', {
                'room': availableRooms[room.index].id,
                // 'category': room.categoryIndex >= 0 ? availableRooms[room.index].types[room.categoryIndex].id : ''
                'category': room.categoryIndex ? availableRooms[room.index].types[room.categoryIndex].id : ""
            });
        }
        this.state.menuSelected && this.setState({ menuSelected: false });
        this.setState({ room: room });
        update = true;
    }

    filterClicked = (stylesSelected) => {
        this.setState({ stylesSelected: stylesSelected });
        update = true;
    }

    heartClicked = () => {
        this.props.navigation['push']('Hearts', {});
    }

    menuClicked = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

        this.setState({ menuSelected: !this.state.menuSelected });
        this.setState({ filterSelected: (this.state.filterSelected = false) })
        update = true;
    }

    showFilterClicked = () => {

        this.setState({ filterSelected: !this.state.filterSelected });
        this.setState({ menuSelected: (this.state.menuSelected = false) })
    }

    sortClicked = (sortMethod) => {
        this.setState({ sortMethod: sortMethod });
    }

    // Maps the hearts to the itemArr
    // Called via localStorage after hearts are loaded
    mapHeartsItems = () => {
        // console.log("mapping hearts", this.hearts);
        for (const item of this.state.hearts) {
            const index = this.itemArr.map(function (x) { return x.name; }).indexOf(item.name);
            index >= 0 && (this.itemArr[index].heart = true);
        }
    }

    // // Receive the items from local storage and map them to the items array
    // emitHearts = (hearts, mapFlag) => {
    //     // emitHearts can be called with or without the hearts array.
    //     // If it is called with the array - update the this.heart array with what's received
    //     hearts && (this.hearts = hearts);
    //     console.log("emitHeartCount:", this.hearts.length);
    //     DeviceEventEmitter.emit('heartEvent', { heartCount: this.hearts.length });
    // }

    // heartToggle = (item, delay) => {
    //     console.log("hearts:", this.hearts);
    //     if (item.heart) {
    //         this.hearts.push(item);
    //         utils.localStorage('hearts', JSON.stringify(this.hearts), 'save', null, null);
    //     } else {
    //         const index = this.hearts.map(function (x) { return x.name; }).indexOf(item.name);
    //         if (index > -1) {
    //             // Future - if delay - then delay writing to local storage so it doesn't disappear
    //             this.hearts.splice(index, 1);
    //             utils.localStorage('hearts', JSON.stringify(this.hearts), 'save', null, null);
    //         }
    //     }
    //     this.emitHearts();
    //     // update = false;
    // }

    // Receive the itemCount from the Items component and broadcast it for Filters to listen
    filteredItemCount = (itemCount) => {
        // this.state.itemCount = itemCount;
        // console.log("filteredItemCount: this.state.itemCount:", this.state.itemCount);
        this.itemCount = itemCount;
        DeviceEventEmitter.emit('filterEvent', { itemCount: itemCount });
    }

    showHideMenu = () => {
        // If the screen goes from narrow to wide hide the menu 
        if (this.state.WI.i > 0 && this.state ?.menuSelected) {
            this.menuClicked();
        }
    }

    setColWidths() {
        this.state.CW = {
            left: this.state.menuSelected ? Layout.menuWidth : (this.state.filterSelected ? 0 : this.state.WI.w.left[this.state.WI.i]),
            right: this.state.filterSelected ? Layout.filterWidth : this.state.WI.w.right[this.state.WI.i]
        }
        this.state.CW.body = this.props.myWindowDimensions.width - this.state.CW.right - this.state.CW.left;
    }

    searchTextListener = () => {
        let self = this;
        const searchTextEventListener = DeviceEventEmitter.addListener("searchTextEvent", (event) => {
            // self.query = event.searchText;
            this.forceUpdate();
            searchTextEventListener.remove();
        });
    }

    render() {
        this.state.WI = utils.getWidthInfo(this.props.myWindowDimensions.width, this.props.myWindowDimensions.height);
        this.showHideMenu();
        this.setColWidths();
        this.searchTextListener();
        return (
            <SafeAreaProvider>
                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        height: 400
                    }}
                >

                    {/* The room/category column */}
                    <View
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        {this.state.CW.left > 0 &&
                            <View style={{
                                width: this.state.CW.left,
                                backgroundColor: Colors.ROOM
                            }}
                            >
                                {!this.state.menuSelected &&
                                    <VS.Logo paddingLeft={30} />
                                }
                                <Rooms
                                    menuSelected={this.state.menuSelected}
                                    wideness={this.state.WI.i}
                                    // ShowIconParent={this.ShowIcon}
                                    // Pass these functions down to the Rooms accordion
                                    menuClickedParent={this.menuClicked}
                                    roomClickedParent={this.roomClicked}
                                    categoryClickedParent={this.categoryClicked}
                                    catalogRoom={this.state.room}
                                    navigation={this.props.navigation}
                                />
                            </View>
                        }
                    </View>

                    {/* The body column */}
                    <View style={{
                        flexDirection: 'column',
                        width: this.state.CW.body,
                        backgroundColor: Colors.BODY,
                        // justifyContent: 'center',
                        // flex: 1,
                        // By setting the height explicitly - the empty selection text can be vertically centered
                        height: (this.state.room ?.expanded ? '100%' : this.state.WI.h)
                    }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                                <ShowIcon.Catalog
                                    show={this.state.CW.left == 0}
                                    icon={"bars"}
                                    size={21}
                                    state={this.state}
                                    colorActive={Colors.RED}
                                    colorInactive={Colors.DARKGRAY}
                                    text={""}
                                    press={this.menuClicked}
                                />

                                {/* Show the logo in the body column if the room column is hidden or if the room menu is selected*/}
                                {(this.state.CW.left == 0 || this.state.menuSelected) &&
                                    // {/* <VS.Icon/> */}
                                    <VS.Logo paddingLeft={this.state.CW.left > 0 ? 20 : 0} />
                                }
                            </View>
                            <SearchInput
                                show={(this.state.CW.left > 0 && !this.state.menuSelected)}
                                row={1}
                                bodyWidth={this.state.CW.body}
                                showHeartCart={((this.state.room ?.expanded || this.itemCount > 0) && this.state.CW.right == 0)}
                            />
                            <HeartCart
                                // show={false}
                                show={((this.state.room ?.expanded || this.itemCount > 0) && this.state.CW.right == 0)}
                                id={1}
                                showFilterColumn={(this.state.filterSelected || this.state.CW.right > 0)}
                                // heartCount={this.hearts?.length}
                                heartCount={this.state.heartCount}
                                press={this.heartClicked}
                            />
                        </View>
                        <SearchInput
                            show={(this.state.CW.left == 0 || this.state.menuSelected)}
                            row={2}
                            bodyWidth={this.state.CW.body}
                            showHeartCart={false}
                        />
                        <VsItems
                            itemArr={this.itemArr}
                            roomSelected={this.state.room}
                            menuSelected={this.state.menuSelected}
                            colWidth={this.state.WI.w.body[this.state.WI.i]}
                            wideness={this.state.WI.i}
                            // columns={this.state.WI.w.columns}
                            screenHeight={this.state.WI.h}
                            showRooms={this.state.CW.left > 0}
                            menuWidth={this.state.CW.left}
                            itemWidth={this.state.CW.body}
                            stylesSelected={this.state.stylesSelected}
                            filterWidth={this.state.CW.right}
                            showFilterClickedParent={this.showFilterClicked}
                            showFilter={this.state.filterSelected}
                            sortMethod={this.state.sortMethod}
                            filteredItemCountParent={this.filteredItemCount}
                            // heartToggleParent={this.heartToggle}
                            heartsParent={this.state.hearts}
                            searchText={searchTextGlobal}
                        />
                    </View>

                    {/* The filters column */}
                    {/* Show the filters only if a room is selected or search text */}
                    {/* { (this.state.room?.expanded && this.state.CW.right > 0) && */}
                    <View
                        style={{
                            // flex: 3,
                            flexDirection: 'column',
                            width: ((this.state.room ?.expanded || searchTextGlobal.length > 2) ? this.state.CW.right : 0),
                            backgroundColor: Colors.FILTER
                        }}
                    >
                        <HeartCart
                            show={((this.state.room ?.expanded || searchTextGlobal.length > 2) && this.state.CW.right > 0)}
                            id={2}
                            heartCount={this.state.heartCount}
                            showFilterColumn={true}
                            press={this.heartClicked}
                        />
                        <Filters
                            show={((this.state.room ?.expanded || searchTextGlobal.length > 2) && this.state.CW.right > 0)}
                            filterClickedParent={this.filterClicked}
                            filterSelected={this.state.filterSelected}
                            showFilterClickedParent={this.showFilterClicked}
                            sortClickedParent={this.sortClicked}
                            sortMethod={this.state.sortMethod}
                            // showFilter={this.state.filterSelected}
                            // roomExpanded={this.state.room ?.expanded}
                            searchText={searchTextGlobal}
                        // filterWidth={(this.state.room ?.expanded ? this.state.CW.right : 0)}

                        />
                    </View>
                </View>
            </SafeAreaProvider>
        )
    }
}

export default withWindowDimensions(Catalog);