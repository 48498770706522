import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View, Text } from 'react-native';
import Categories from './Categories'
import { Colors } from '../constants/Colors';

import myStyles from '../styles/myStyles';

import ShowIcon from '../components/ShowIcon';

import availableRooms from '../data/vs.availableRooms';

export default class Rooms extends Component {

	constructor(props) {
		super(props);
		this.state = {
			availableRooms: availableRooms,
			prevRoom: props.catalogRoom,
			//prevCategory: null,
			// catalogRoom: props.catalogRoom,
			// catalogCategory: props.catalogCategory,
			// menuSelected: props.menuSelected,
			wideness: props.wideness,
			roomClickedParent: props.roomClicked,
			menuClickedParent: props.menuClicked,
			categoryClickedParent: props.categoryClicked,
			roomCategoryChanged: props.roomCategoryChanged
		}
	}

	componentDidMount = () => {
		// console.log("Rooms did mount");
	}
	// If a room is clicked
	roomClicked = (room) => {

		// console.log("Rooms -> roomClicked -> room/prevRoom:", room, this.state.prevRoom);

		this.props.roomClickedParent(room);
		// this.setState({prevRoom: room});
	}

	heartClicked = () => {
        this.props.navigation['push']('Hearts', {});
    }

	categoryClicked = (room) => {
		this.props.categoryClickedParent(room);
	}

	renderAccordions = () => {
		const items = [];
		// console.log("Rooms -> renderAccordions -> catalogRoom:", this.state.room, this.state.prevRoom);
		for (const [index, item] of this.state.availableRooms.entries()) {
			items.push(
				<Categories
					key={item.name}
					name={item.name}
					index={index}
					categories={item.types}
					roomClickedParent={this.roomClicked}
					categoryClickedParent={this.categoryClicked}
					menuClicked={this.props.menuClicked}
					catalogRoom={this.props.catalogRoom}
				// prevRoom={this.state.prevRoom}
				/>
			);
		}
		return items;
	}

	render() {
		return (
			<View>
				<ShowIcon.Room
					icon={'xmark'}
					press={this.props.menuClickedParent}
					size={21}
					color={Colors.DARKGRAY}
					show={this.props.menuSelected}
					text={'Menu'}
				/>
				<View style={myStyles.common.padding} />
				<View style={myStyles.common.hr} />
				<View style={myStyles.common.padding} />
				<TouchableOpacity style={[myStyles.room.row, myStyles.common.btnInactive]} 
					onPress={() => this.heartClicked()}>
                    <Text style={[myStyles.common.title]}>Favorites</Text>
                </TouchableOpacity>
				<View style={myStyles.common.padding} />
				<View style={myStyles.common.hr} />
				<View style={myStyles.common.padding} />
				<View style={myStyles.room.container}>
					{this.renderAccordions()}
				</View>
			</View>
		);
	}
}
