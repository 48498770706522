import React, { Component } from 'react';
import { TouchableOpacity, FlatList, View, Animated, Easing, Clipboard, Text, DeviceEventEmitter, useWindowDimensions } from 'react-native';
import { Colors } from '../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import myStyles from '../styles/myStyles';
import utils from '../utils/utils';
import RenderItem from '../components/RenderItem';
import { Layout } from '../constants/Layout';
import Header from '../components/Header';
import VS from '../components/VS';

const imageRootURL = VS.RootURL;

const withWindowDimensions = Component => props => {
    const myWindowDimensions = useWindowDimensions();
    return <Component {...props} myWindowDimensions={myWindowDimensions} />;
};


// const Styles = (item) => {
//     return (
//         <View style={myStyles.common.itemInfoBottom}>
//             <Text numberOfLines={1} ellipsizeMode='tail' style={myStyles.common.itemStyleText}>{item.styles.join(' | ')}</Text>
//         </View>
//     )
// }

const FavoritesLink = ({ url, hearts, config }) => {

    let toastOpacity = new Animated.Value(0);

    const copyShareableLink = () => {

        Animated.timing(toastOpacity, {
            toValue: 1,
            duration: 300,
            easing: Easing.out(Easing.ease),
            useNativeDriver: false
        }).start(() => {
            setTimeout(() => {
                Animated.timing(toastOpacity, {
                    toValue: 0,
                    duration: 300,
                    easing: Easing.out(Easing.ease),
                    useNativeDriver: false,
                }).start();
            }, 600);
        });

        // Generate a unique id;
        const vsId = utils.objectId();
        const link = window.location.origin + '/favorites/' + vsId;
        Clipboard.setString(link);

        const parseData = {
            vsid: vsId,
            user: VS.globals.userName,
            ip: VS.globals.ip,
            domain: window.location.hostname,
            applicationId: VS.globals.version,
            name: 'catalog',
            favorites: hearts
        }

        utils.parse.create(parseData, null, (response) => {
            // console.log("parseUpdate response: ", response);
        });

        // Old code that allowed parse data to be updated on parse server
        // Will any use case require it?
        //     const link = window.location.origin + '/favorites/' + parseId;
        //     Clipboard.setString(link);
        //     // alert("old link:"+ link);
        //     utils.parse.update(parseData, parseId, null);

    }

    return (
        <View style={{
            alignItems: 'center',
            backgroundColor: '#DEDEDE', height: config ?.source != 'remote' ? 120 : 60,
            paddingTop: 10,
            paddingBottom: 10
        }}>
            <Text style={{
                textAlign: 'center',
                fontSize: 32,
                fontWeight: 'regular',
                color: Colors.DARKGRAY,
                fontFamily: "LatoLight",
                paddingTop: 2,
                paddingBottom: 8
            }}>
                {config ?.pronoun} FAVORITES
            </Text>

            {
                config ?.source != 'remote' &&
                    <TouchableOpacity
                        style={{
                            paddingTop: 10,
                            paddingBottom: 2
                        }}
                        onPress={() => copyShareableLink()
                        } >
                        <View style={[myStyles.common.buttonView, { backgroundColor: "#eeeeee", width: 200 }]}>
                            <FontAwesomeIcon icon={'share-nodes'} size={18} color={Colors.MEDGRAY} />
                            <Text style={{
                                fontSize: 18,
                                fontWeight: 'regular',
                                color: Colors.DARKGRAY,
                                fontFamily: "LatoLight",
                                paddingLeft: 0,
                                paddingRight: 0
                            }} >Copy shareable link</Text>
                            {/* <FontAwesomeIcon icon='angle-right' size='20' color='white' /> */}
                        </View>
                        <Animated.View style={{ top: -54, left: 36, opacity: toastOpacity }}>
                            <Text style={{ fontSize: 14, color: Colors.MEDGRAY }}>Copied to clipboard...</Text>
                        </Animated.View>
                    </TouchableOpacity>
            }
        </View>
    )
}

const UndoImage = ({ item, undoBarHeight }) => {

    const itemImage = imageRootURL + item.loc + item.name + '/' + item.name + 'front.png';
    return (
        <View>
            <Animated.Image
                source={{ uri: itemImage }}
                style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    height: Layout.undoBarImageHeight,
                    width: Layout.undoBarImageHeight * 2,

                    transform: [
                        {
                            scaleX: undoBarHeight.interpolate({
                                inputRange: [0, Layout.undoBarHeight],
                                outputRange: [0.2, 1]
                            })
                        },
                        {
                            scaleY: undoBarHeight.interpolate({
                                inputRange: [0, Layout.undoBarHeight],
                                outputRange: [0.2, 1]
                            })
                        }
                    ]
                    // marginRight: 10
                }}
                resizeMode="contain"
            />
        </View>
    )
}

class Hearts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hearts: [],
            WI: utils.getWidthInfo(this.props.myWindowDimensions.width, this.props.myWindowDimensions.height),
            heartRemoved: null,
            listenerCreated: false,
            config: {
                source: 'local',
                pronoun: 'YOUR'
            }

        }

        // If the URL includes a parseId
        if (this.props.route.params ?.vsId) {
            // Read the hearts from remote storage
            const query = {
                key: 'vsid',
                value: this.props.route.params ?.vsId,
                collection: 'favorites'
            }
            utils.parse.find(query, null, (response) => {
                if (response.favorites) {
                    // If favorites do exist remotely
                    this.setState({ hearts: response.favorites });
                    this.setState({
                        config: {
                            source: 'remote',
                            pronoun: response.user.toUpperCase() + "'S"
                        }
                    })
                } else {
                    // Read the hearts from local storage
                    this.props.navigation['push']('Hearts', {});
                    utils.localStorage('hearts', null, 'readHearts', { functions: [this.saveHearts] }, null, null);
                }
            }, null);
        } else {
            // Read the hearts from local storage
            utils.localStorage('hearts', null, 'readHearts', { functions: [this.saveHearts] }, null, null);
        }
    }

    undoBarHeight = new Animated.Value(0)
    heartEventListener = null
    // parseEventListener = null

    expand = (undoBarHeight) => {
        //    this.undoAnim = new Animated.Value(0);

        Animated.timing(this.undoBarHeight, {
            toValue: undoBarHeight,
            duration: 400,
            easing: Easing.out(Easing.ease),
            useNativeDriver: false,
        }).start();
    };

    contract = (undoBarHeight, cb, args) => {
        Animated.timing(this.undoBarHeight, {
            toValue: undoBarHeight,
            duration: 400,
            easing: Easing.in(Easing.ease),
            useNativeDriver: false,
            // }).start(cb(arg1, arg2, arg3));
        }).start(() => {
            cb(args);
        });
    }

    autoResize = (undoBarHeight) => {
        setTimeout(() => {
            Animated.timing(this.undoBarHeight, {
                toValue: 0,
                duration: 400,
                easing: Easing.out(Easing.ease),
                useNativeDriver: true,
            }).start();
        }, 5000);
    };

    UndoBar = () => {
        const successColor = "#6dcf81";
        const successHeader = "Success!";
        const warnColor = "#c3b028"
        const successMessage = "You pressed the success button";

        const closeUndo = () => {
            this.setState({ heartRemoved: null });
        }

        const toggleHeart = ([item, hearts, index]) => {
            item.heart = !item.heart;
            // this.props.heartToggleParent(item);
            utils.heartToggle(item, hearts, 'splice', index, this.state.config);
            this.setState({ heartRemoved: null });
            // expand( item.heart ? 40: 80);
        }

        // const boxStyle = {
        //     height: this.state.undoBarHeight,
        // };


        if (this.state.heartRemoved) {
            return (
                <View>
                    <View style={myStyles.heart.hr} />
                    <Animated.View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            paddingRight: 10,
                            alignItems: 'center',
                            backgroundColor: '#efefef',
                            paddingLeft: 8,
                            height: this.undoBarHeight,
                        }}
                    >
                        {/* <Text style={myStyles.heart.undoText}> Item </Text> */}
                        <UndoImage
                            item={this.state.heartRemoved}
                            undoBarHeight={this.undoBarHeight}
                        />
                        {/* <Text style={myStyles.heart.undoText}> {this.state.heartRemoved.name} </Text> */}
                        <Animated.Text style={[myStyles.heart.undoText, {

                            opacity: this.undoBarHeight.interpolate({
                                inputRange: [0, Layout.undoBarHeight],
                                outputRange: [-1, 1]
                            })

                        }]}> removed from favorites!</Animated.Text>
                        {/* <FontAwesomeIcon icon={'exclamation'} size={21} color={warnColor} /> */}

                        <TouchableOpacity
                            style={{
                                paddingLeft: 9,
                                paddingTop: 6,
                                paddingBottom: 6
                            }}
                            // onPress={() => toggleHeart(this.state.heartRemoved, this.state.hearts, this.state.index)
                            onPress={() => this.contract(0, toggleHeart, [this.state.heartRemoved, this.state.hearts, this.state.index])
                            } >
                            <Animated.View style={[myStyles.common.buttonView, {
                                backgroundColor: "#777777",
                                width: 100,
                                transform: [
                                    {
                                        scaleY: this.undoBarHeight.interpolate({
                                            inputRange: [0, Layout.undoBarHeight],
                                            outputRange: [0, 1]
                                        })
                                    }]
                            }]}>
                                <FontAwesomeIcon icon={'undo'} size={18} color={Colors.WHITE} />
                                <Text style={{
                                    fontSize: 15,
                                    fontWeight: 'regular',
                                    color: Colors.WHITE,
                                    fontFamily: "LatoLight",
                                    paddingLeft: 0,
                                    paddingRight: 4
                                }} >Undo</Text>
                            </Animated.View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={closeUndo} style={{ paddingLeft: 10 }}>
                            <FontAwesomeIcon icon={'xmark'} size={21} color={Colors.DARKGRAY} />
                        </TouchableOpacity>
                    </Animated.View>
                    <View style={myStyles.heart.hr} />
                </View>
            )
        } else {
            return null;
        }
    }

    // flatListHeight = this.WI.h - Layout.marginTop;
    // numColumns = this.WI.i || 1;

    // Receive the itemCount from the Items component and broadcast it for Filters to listen
    saveHearts = (hearts) => {
        this.setState({ hearts: hearts });
    }

    heartCountListener = () => {
        if (this.heartEventListener) this.heartEventListener.remove();
        this.heartEventListener = DeviceEventEmitter.addListener("heartEvent", (event) => {
            this.heartEventListener.remove();
            // this.setState({ hearts: event.hearts });
            if (event.type == 'delete') {
                this.setState({ heartRemoved: event.item, index: event.index });
                this.expand(Layout.undoBarHeight);
            }
            // this.forceUpdate();
        });
    }

    onRefresh = () => {
        console.log("onRefresh");
    }

    refreshing = () => {
        console.log("refreshing");
    }

    onEndReached = () => {
        console.log("onReachEnd");
    }

    browseClicked = () => {
        this.props.navigation['push']('Catalog', {});
    }

    render() {
        this.heartCountListener();
        // this.parseListener();
        const colWidth = this.props.myWindowDimensions.width;
        this.state.WI = utils.getWidthInfo(this.props.myWindowDimensions.width, this.props.myWindowDimensions.height);
        // this.numColumns = this.WI.i || 1;
        // const numColumns = this.state.WI.w.columns[this.state.WI.i] || 1;
        const numColumns = Math.round(colWidth / Layout.itemContainerWidth - 1) + 1;
        this.flatListHeight = this.state.WI.h - Layout.marginTop;

        return (
            <View>
                <Header
                    browseClickedParent={this.browseClicked}
                />
                <FavoritesLink
                    url={this.props.route}
                    hearts={this.state.hearts}
                    config={this.state.config}
                />
                <this.UndoBar />
                <FlatList
                    data={this.state.hearts}
                    numColumns={numColumns}
                    key={colWidth}
                    keyExtractor={item => `${item.name}`}
                    renderItem={({ item, index }) =>
                        <RenderItem
                            screen={'favorites'}
                            item={item}
                            index={index}
                            // category={category}
                            // roomName={'bedroom'}
                            itemCount={this.state.hearts.length}
                            width={colWidth / numColumns}
                            Styles={VS.Styles}
                            heartsParent={this.state.hearts}
                            config={this.state.config}
                        />
                    }
                    scrollEnabled={true}
                    style={{
                        height: this.flatListHeight
                    }}
                    // onRefresh={this.onRefresh}
                    // refreshing={this.refreshing}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={0.95}
                />
            </View>
        )
    }
}


export default withWindowDimensions(Hearts);