import React, { useState } from 'react';
import { Text, Image, StyleSheet, TouchableOpacity, View, FlatList, DeviceEventEmitter } from 'react-native';
import { Colors } from '../constants/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import availableRooms from '../data/vs.availableRooms';
import myStyles from '../styles/myStyles';

import RenderItem from '../components/RenderItem';
// let count = { room: null, category: null };

import { Layout } from '../constants/Layout';
import VS from '../components/VS'

const VsItems = ({
	itemArr,
	roomSelected,
	menuSelected,
	colWidth,
	wideness,
	// columns,
	screenHeight,
	showRooms,
	menuWidth,
	itemWidth,
	stylesSelected,
	sortMethod,
	filterWidth,
	showFilterClickedParent,
	showFilter,
	filteredItemCountParent,
	heartToggleParent,
	heartsParent,
	searchText
}) => {

	let roomName, category, itemCount, vsItemList, categorySelected;
	let refreshing = false;
	let listData = [];	// Changes with scroll and is copied from vsItemList
	// const itemHeight = 280;
	// const itemPadding = 0;
	// const rowHeight = itemHeight+itemPadding;
	const flatListHeight = screenHeight - Layout.marginTop;
	// console.log("flatListHeight", flatListHeight, screenHeight);

	// const visibleRows = Math.round(screenHeight/rowHeight)+1;
	// const numColumns = wideness || 1;
	const numColumns = Math.round(colWidth / Layout.itemContainerWidth - 1) + 1;


	// const visibleItems = numColumns * visibleRows;

	let query = '';

	// const Styles = (item) => {
	// 	const styleText = item.styles.join(' | ');
	// 	return (
	// 		<View style={[myStyles.common.itemInfoBottom, {width: 300}]}>
	// 			<Text numberOfLines={1} ellipsizeMode='tail' style={myStyles.common.itemStyleText}>{styleText}</Text>
	// 		</View>
	// 	)
	// }

	// const incrementList = (increment) => {
	// 	listData = vsItemList.slice(0, listData.length+increment);
	// 	// console.log("listData", listData);
	// }

	const filterItems = () => {
		roomName = roomSelected ?.expanded ? availableRooms[roomSelected ?.index] ?.name : null;
		categorySelected = roomName ? availableRooms[roomSelected ?.index] ?.types[roomSelected ?.categoryIndex] : null;
		category = (categorySelected ?.value || !showRooms) ? categorySelected ?.type : null;

		// console.log("items -> filteritems -> searchText:", roomSelected, searchText);

		vsItemList = [];
		if (category) {
			if (stylesSelected.length) {
				vsItemList = itemArr.filter(a => (
					a.rooms.includes(roomName) &&
					a.types.includes(category) &&
					a.styles.some(b => stylesSelected.includes(b))
				));
			} else {
				vsItemList = itemArr.filter(a => (a.rooms.includes(roomName) && a.types.includes(category)));
			}
			// count.category = vsItemList.length;
			// itemCount = count.category +" / "+count.room;
			itemCount = vsItemList.length;
		} else {
			if (stylesSelected.length) {
				vsItemList = itemArr.filter(
					a => a.rooms.includes(roomName) &&
						a.styles.some(b => stylesSelected.includes(b))
				);
			} else {
				vsItemList = itemArr.filter(a => a.rooms.includes(roomName));
			}

			// count.room = vsItemList.length;
			// count.category = null;
			// itemCount = count.room +" / "+vsItemData.length;
			itemCount = vsItemList.length;
		}

		const arr = vsItemList.length > 0 ? vsItemList : itemArr;
		if (searchText.length > 2) {
			const q = searchText.toLowerCase();
			vsItemList = arr.filter(a => (
				a.rooms.some(b => b.toLowerCase().includes(q)) ||
				a.types.some(b => b.toLowerCase().includes(q)) ||
				a.styles.some(b => b.toLowerCase().includes(q)) ||
				a.tags.some(b => b.toLowerCase().includes(q)) ||
				a.name.toLowerCase().includes(q)
			));
		}

		vsItemList.sort((a, b) => {
			if (sortMethod == 'Oldest') {
				return a.name.localeCompare(b.name);
			}
			if (sortMethod == 'Newest') {
				return b.name.localeCompare(a.name);
			}
			if (sortMethod == 'Popularity') {
				// console.log("popularity: "+a.popularity.countPerMonth+' > '+b.popularity.countPerMonth);
				// if(1?.popularity?.countPerMonth !== null && b?.popularity?.countPerMonth !== null)
				if (a.popularity.countPerMonth > b.popularity.countPerMonth) return -1;
				if (a.popularity.countPerMonth < b.popularity.countPerMonth) return 1;
				return 0;
			}
		});
		return vsItemList.length;
	}

	const CloseMenu = () => {
		return (
			<View>
				<Text style={[styles.text, { paddingTop: 60, paddingBottom: 60 }]}>Close menu to view results</Text>
			</View>
		)
	}

	const SelectRoom = ({location}) => {
		const [domainsLoaded, setDomainsLoaded] = useState(false);

		// Update the splash text based on the domain info
		VS.listenParseDomain(['splash'], setDomainsLoaded);
	
		const infoText = location ?
			'Search or select room on left' :
			'Search or select room from menu';

		return (
			<View style={styles.emptyContainer}>
				<View style={styles.emptyContainer}>
					<Text style={styles.text}>{infoText}</Text>
				</View>
				<View style={styles.emptyContainer}>
					<Text style={styles.textSmall}>{VS.globals.domains.splash}</Text>
				</View>
			</View>
		)
	}

	const NoItemsFound = () => {
		return (
			<View style={styles.emptyContainer}>
				<Text style={styles.text}>No items found!</Text>
			</View>
		)
	}

	const onRefresh = () => {
		refreshing = false;
	}

	const onEndReached = () => {
		// console.log("On end reached");
	}

	const RenderItems = () => {
		const itemCount = filterItems();

		filteredItemCountParent(itemCount);

		const title = (roomName + (category ? (" | " + category) : "")).toUpperCase();
		// incrementList( visibleItems);

		return (
			<View>
				{
					menuSelected &&
					<CloseMenu />
				}
				{
					menuWidth > 0 &&
					<View style={myStyles.body.header}></View>
				}
				<View style={menuSelected ? [styles.itemHalfContainer, { width: colWidth - menuWidth }] : styles.itemFullContainer}>
					<View style={myStyles.body.header}>
						<Text style={[myStyles.common.roomTitle]} >{title}</Text>
						<View style={myStyles.body.header}>
							{(filterWidth == 0) &&
								<Text style={myStyles.common.itemCount} >{itemCount} items</Text>
							}
							{(filterWidth == 0) &&
								<TouchableOpacity
									onPress={() => showFilterClickedParent()}
									style={{ paddingRight: 10 }}
								>
									<View style={[myStyles.common.buttonView,]}>
										<Text style={myStyles.common.buttonText} >FILTERS</Text>
										{
											stylesSelected.length > 0 &&
											<Text style={myStyles.common.buttonText}>({stylesSelected.length})</Text>
										}
										<FontAwesomeIcon icon='angle-right' size='20' color='white' />
									</View>
								</TouchableOpacity>
							}
						</View>
					</View>
					<View style={myStyles.common.parentHr} />
					<FlatList
						data={vsItemList}
						numColumns={numColumns}
						keyExtractor={item => `${item.name}`}
						renderItem={({ item, index }) =>
							<RenderItem
								item={item}
								index={index}
								category={category}
								roomName={roomName}
								itemCount={itemCount}
								width={colWidth / numColumns}
								Styles={VS.Styles}
								// heartToggleParent={heartToggleParent}
								heartsParent={heartsParent}
							/>
						}
						scrollEnabled={true}
						style={{
							height: flatListHeight
						}}
						onRefresh={onRefresh}
						refreshing={refreshing}
						onEndReached={onEndReached}
						onEndReachedThreshold={0.95}
					/>
				</View>
				{
					itemCount == 0 &&
					<NoItemsFound />
				}
				<TouchableOpacity
					style={{
						borderWidth: 1,
						borderColor: 'rgba(0,0,0,0.1)',
						alignItems: 'center',
						justifyContent: 'center',
						width: 50,
						position: 'absolute',
						bottom: 150,
						right: 20,
						height: 50,
						opacity: 0.05,
						backgroundColor: '#dedede',
						borderRadius: 100,
					}}
				// onPress={() => showFilterClickedParent()}
				>
					{/* <FontAwesomeIcon icon='circle' size='40' color='red' />  */}
					{/* <FontAwesomeIcon icon={'xmark'} size={20} /> */}
				</TouchableOpacity>
			</View>
		)
	}

	if (roomSelected ?.expanded || searchText.length > 2) {
		return (
			<RenderItems />
		)
	} else {
		return (
			<SelectRoom location={showRooms}/>
		)
	}
	
	// if (showRooms) {
	// 	return (
	// 		<SelectRoomLeft />
	// 	)
	// } else {
	// 	return (
	// 		<SelectRoomMenu />
	// 	)
	// }
}

export default VsItems

const styles = StyleSheet.create({
	emptyContainer: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		// opacity: 0.5
	},
	itemHalfContainer: {
		opacity: 0.25
	},
	itemFullContainer: {
		flex: 1
	},
	text: {
		textAlignVertical: 'center',
		fontSize: 32,
		fontWeight: 'normal',
		color: Colors.DARKGRAY,
		fontFamily: "LatoRegular",
		paddingLeft: 20,
		paddingRight: 20,
	},
	textSmall: {
		textAlignVertical: 'center',
		fontSize: 24,
		fontWeight: 'normal',
		color: Colors.DARKGRAY,
		fontFamily: "LatoRegular",
		paddingLeft: 20,
		paddingRight: 20,
	}
});
