import { Image, View, Text, Linking, DeviceEventEmitter} from 'react-native';
import {useState} from 'react';
import myStyles from '../styles/myStyles';
import { Layout } from '../constants/Layout';
import { Colors } from '../constants/Colors';
import { TouchableOpacity } from 'react-native-gesture-handler';

//const RootURL = 'http://catalog.features.visualstager.com/items/';
const RootURL = 'https://48ta.visualstager.com/items/';

let globals = {};

globals.version = 23;``

const Icon = () => {
    const vsIcon = require('../assets/vsIcon.png');
    return (
        <View>
            <Image
                source={vsIcon}
                style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    height: 30,
                    width: 30,
                    // marginRight: 10
                }}
                resizeMode="contain"
            />
        </View>
    )
}


const listenParseDomain = (keys, stateFunction) => {
    if(globals?.domains?.loaded) return;
    const parseDomainListener = DeviceEventEmitter.addListener("parseDomain", (event)=>{
        parseDomainListener.remove();
        const domainInfo = event?.data?.results[0];
        globals.domains.loaded = true;
        for(const key of keys) {
            globals.domains[key] = domainInfo?.active ? domainInfo[key] : globals.inactiveDomain[key];
        }
        // Force a re-render
        stateFunction(true);
    });
};

const Styles = ({styles, width}) => {
    const styleText = styles.join(' | ');
    return (
        <View style={[myStyles.common.itemInfoBottom, {width: width}]}>
            <Text numberOfLines={1} ellipsizeMode='tail' style={myStyles.common.itemStyleText}>{styleText}</Text>
        </View>
    )
}


const Logo = ({ paddingLeft, backgroundColor }) => {

    const [domainsLoaded, setDomainsLoaded] = useState(false);

    listenParseDomain(['logo'], setDomainsLoaded);
    
    return (
        <TouchableOpacity 
        onPress={() => {
            Linking.canOpenURL(globals.domains.url)
        }}
        style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            height: 50,
            paddingRight: 18,
            alignItems: 'center',
            backgroundColor: backgroundColor,
            paddingLeft: paddingLeft
        }}>
            <Image
                source={{ uri: globals.domains.logo }}
                // source={logo}
                style={{
                    height: 30,
                    top: 2,
                    left: -10,
                    width: Layout.logoWidth,
                    // marginRight: 10
                }}
                resizeMode="contain"
            />
        </TouchableOpacity>
    )
}

export default {
    Icon: Icon,
    Logo: Logo,
    Styles: Styles,
    RootURL: RootURL,
    globals: globals,
    listenParseDomain: listenParseDomain
};