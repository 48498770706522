import React, { useState } from 'react';

import { Text, View, Platform, StyleSheet } from 'react-native';

import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faXmark, faBars, faFilter, faCheck, faCheckSquare,
	faAngleUp, faAngleDown, faPlus, faMinus, faAngleRight, faCoffee,
	faHeart as fasHeart, faMagnifyingGlass as fasMagnifyingGlass, faShareNodes,
	faExclamation, faCircleExclamation, faUndo
} from '@fortawesome/free-solid-svg-icons';
import {
	faCircle as farCircle,
	faCheckCircle as farCheckCircle,
	faHeart as farHeart,
	faCopy as farCopy
} from '@fortawesome/free-regular-svg-icons';

library.add(
	faXmark, faBars, faFilter, faCheckSquare,
	faCoffee, farCircle as IconDefinition, farCheckCircle as IconDefinition,
	faCheck, faAngleUp, faAngleDown, faAngleRight, faPlus, faMinus, fasHeart, farHeart as IconDefinition,
	fasMagnifyingGlass, faShareNodes, faExclamation, faCircleExclamation, faUndo, farCopy as IconDefinition
);

import utils from './app/utils/utils';

import * as Network from 'expo-network';

import Catalog from './app/screens/Catalog';

import Hearts from './app/screens/Hearts';

import { NavigationContainer, NavigationContext } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { useFonts } from 'expo-font';

// import domains from './app/data/domains.json';
// const domainJSON = 'https://48ta.visualstager.com/domains.json';


import VS from './app/components/VS'

const getIp = async () => {
	try {
		const ip = await Network.getIpAddressAsync();
		VS.globals.ip = ip;
	} catch (e) {
		onError && console.log("Error reading ip address");
	}
}

const hostname = Platform.OS == 'web' ? window.location.hostname : 'localhost';

// console.log("Platform", Platform);
const query = {
	key: 'domain',
	value: hostname,
	collection: 'domains'
}


// VS.globals.domains = domains[window.location.hostname] || domains.default;

VS.globals.domains = {
	// logo: "assets/activity.gif",
	logo: "https://48ta.visualstager.com/logos/activity.gif",
	url: "visualstager.com",
	splash: "",
	active: true,
	loaded: false
}

VS.globals.inactiveDomain = {
	logo: "https://48ta.visualstager.com/logos/vslogo-red-black.svg",
	url: "visualstager.com",
	splash: "VisualStager Item Catalog!\n\nYou can white-label it under your company's name!!",
}

getIp();


// Read or create a local storage value for username
utils.localStorage('vsGuestName', null, 'read', null, null, (userName) => {
	if (!userName) {
		userName = "Guest" + Math.floor(Math.random() * 900 + 100);
		utils.localStorage('vsGuestName', JSON.stringify(userName), 'save', null, null, null);
	}
	VS.globals.userName = userName;
});

const Stack = createStackNavigator();

const NotFound = ({ route }) => {
	return (
		<View style={styles.container}>
			<Text style={styles.text}>Not Found</Text>
		</View>
	);
};

const linking = {
	config: {
		screens: {
			// Catalog: "browse/:room?/:category?",
			// Hearts: "favorites/",
			Catalog: "browse/:room?/:category?",
			Hearts: "favorites/:vsId?",
			NotFound: "*",
		}
	},
};


function App() {
	// const [domainsLoaded, setDomainsLoaded] = useState(false); // integer state

	const [loaded] = useFonts({
		LatoThin: require('./app/assets/fonts/Lato-Thin.ttf'),
		LatoLight: require('./app/assets/fonts/Lato-Light.ttf'),
		LatoRegular: require('./app/assets/fonts/Lato-Regular.ttf'),
		LatoBold: require('./app/assets/fonts/Lato-Bold.ttf'),
		LatoBlack: require('./app/assets/fonts/Lato-Black.ttf'),
	})
	if (!loaded) {
		return null;
	}

	utils.parse.find(query, {event: 'parseDomain', type: 'read'}, (response) => {
		// console.log("parseDomain response:", response, response.logo);
	});
	
	return (
		<NavigationContainer linking={linking}>
			<Stack.Navigator
				screenOptions={{
					headerShown: false
				}}
			>
				{/* <Catalog /> */}
				<Stack.Screen
					name="Catalog"
					component={Catalog}
				/>
				{/* <Hearts /> */}
				<Stack.Screen
					name="Hearts"
					component={Hearts}
				/>
				{/* Not Found */}
				<Stack.Screen
					name="NotFound"
					component={NotFound}
				/>
			</Stack.Navigator>
		</NavigationContainer>
	);
};

export default App;


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'center',
	},
	text: {
		fontSize: 20,
	},
});